// extracted by mini-css-extract-plugin
export var avatar = "ProjectStickyBarRolesDropdown__avatar__QVqzN";
export var avatarPic = "ProjectStickyBarRolesDropdown__avatarPic__l2EaT";
export var column = "ProjectStickyBarRolesDropdown__column__QPsDA";
export var container = "ProjectStickyBarRolesDropdown__container__Kn3D4";
export var currentRole = "ProjectStickyBarRolesDropdown__currentRole__WOAvr";
export var currentRoleWrapper = "ProjectStickyBarRolesDropdown__currentRoleWrapper__sfJWy";
export var dropdownBody = "ProjectStickyBarRolesDropdown__dropdownBody__Z6S6I";
export var dropdownTitle = "ProjectStickyBarRolesDropdown__dropdownTitle__XncCA";
export var flex = "ProjectStickyBarRolesDropdown__flex__U0B27";
export var flexColumn = "ProjectStickyBarRolesDropdown__flexColumn__tyqw2";
export var gap1 = "ProjectStickyBarRolesDropdown__gap1__DAirZ";
export var gap2 = "ProjectStickyBarRolesDropdown__gap2__DGLTa";
export var gap3 = "ProjectStickyBarRolesDropdown__gap3__IUS3Q";
export var gap4 = "ProjectStickyBarRolesDropdown__gap4__zckzB";
export var gap5 = "ProjectStickyBarRolesDropdown__gap5__OcwtJ";
export var icon = "ProjectStickyBarRolesDropdown__icon__tIHnL";
export var item = "ProjectStickyBarRolesDropdown__item__nOes6";
export var ownerJobTitle = "ProjectStickyBarRolesDropdown__ownerJobTitle__IpGOb";
export var ownerName = "ProjectStickyBarRolesDropdown__ownerName__n3a79";
export var rolesList = "ProjectStickyBarRolesDropdown__rolesList__hUHYa";
export var row = "ProjectStickyBarRolesDropdown__row__U3yOJ";
export var search = "ProjectStickyBarRolesDropdown__search__rrN1G";
export var searchFieldInput = "ProjectStickyBarRolesDropdown__searchFieldInput__IVVf7";
export var searchIcon = "ProjectStickyBarRolesDropdown__searchIcon__AhowR";