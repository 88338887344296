// extracted by mini-css-extract-plugin
export var activeTab = "ProjectNavbar__activeTab__sZUkr";
export var barContainer = "ProjectNavbar__barContainer__tuORh";
export var column = "ProjectNavbar__column__xD3u8";
export var flex = "ProjectNavbar__flex__yE2S7";
export var flexColumn = "ProjectNavbar__flexColumn__cQ7Ey";
export var gap1 = "ProjectNavbar__gap1__yupoJ";
export var gap2 = "ProjectNavbar__gap2__KLVYk";
export var gap3 = "ProjectNavbar__gap3__hmkRj";
export var gap4 = "ProjectNavbar__gap4__piiQj";
export var gap5 = "ProjectNavbar__gap5__oFqQJ";
export var row = "ProjectNavbar__row__uxxUU";
export var tabItem = "ProjectNavbar__tabItem__vHB5u";
export var wrapper = "ProjectNavbar__wrapper__Ao6rC";