// extracted by mini-css-extract-plugin
export var column = "Tag__column__UqP8K";
export var flex = "Tag__flex__qV19s";
export var flexColumn = "Tag__flexColumn__zPm6V";
export var gap1 = "Tag__gap1__ckFs6";
export var gap2 = "Tag__gap2__T0I0L";
export var gap3 = "Tag__gap3__fYM6F";
export var gap4 = "Tag__gap4__dB35w";
export var gap5 = "Tag__gap5__xadbl";
export var row = "Tag__row__WzGuz";
export var tagContainer = "Tag__tagContainer__XEKpG";