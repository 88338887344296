// extracted by mini-css-extract-plugin
export var column = "ProjectStickyBar__column__b3vOd";
export var container = "ProjectStickyBar__container__S4kS7";
export var flex = "ProjectStickyBar__flex__v06Hg";
export var flexColumn = "ProjectStickyBar__flexColumn__xra4v";
export var gap1 = "ProjectStickyBar__gap1__R2XeV";
export var gap2 = "ProjectStickyBar__gap2__uiutZ";
export var gap3 = "ProjectStickyBar__gap3__Oi8ZM";
export var gap4 = "ProjectStickyBar__gap4__GhSuy";
export var gap5 = "ProjectStickyBar__gap5__s74aI";
export var providerDropdown = "ProjectStickyBar__providerDropdown__xKcrm";
export var rightCol = "ProjectStickyBar__rightCol__nS3Yb";
export var rolesContainer = "ProjectStickyBar__rolesContainer__Nw459";
export var row = "ProjectStickyBar__row__Z7moE";