// extracted by mini-css-extract-plugin
export var actionContainer = "ProjectStickyBarUsersDropdown__actionContainer__tbBJ9";
export var avatar = "ProjectStickyBarUsersDropdown__avatar__wPaLD";
export var avatarPic = "ProjectStickyBarUsersDropdown__avatarPic__WkWBk";
export var column = "ProjectStickyBarUsersDropdown__column__zj0BF";
export var container = "ProjectStickyBarUsersDropdown__container__HTI8y";
export var dropdownBody = "ProjectStickyBarUsersDropdown__dropdownBody__Uloyr";
export var dropdownTitle = "ProjectStickyBarUsersDropdown__dropdownTitle__uXoh8";
export var flex = "ProjectStickyBarUsersDropdown__flex__H0mOc";
export var flexColumn = "ProjectStickyBarUsersDropdown__flexColumn__BcJnM";
export var gap1 = "ProjectStickyBarUsersDropdown__gap1__CuMsT";
export var gap2 = "ProjectStickyBarUsersDropdown__gap2__QFPKp";
export var gap3 = "ProjectStickyBarUsersDropdown__gap3__YibTm";
export var gap4 = "ProjectStickyBarUsersDropdown__gap4__QhP_j";
export var gap5 = "ProjectStickyBarUsersDropdown__gap5__VRlNj";
export var item = "ProjectStickyBarUsersDropdown__item__Im5_P";
export var label = "ProjectStickyBarUsersDropdown__label__a0pyl";
export var list = "ProjectStickyBarUsersDropdown__list__PsmV2";
export var nameAndBadgeWrapper = "ProjectStickyBarUsersDropdown__nameAndBadgeWrapper__LO7mM";
export var pickedUsers = "ProjectStickyBarUsersDropdown__pickedUsers__VxIJ8";
export var roleTag = "ProjectStickyBarUsersDropdown__roleTag__fqsEA";
export var row = "ProjectStickyBarUsersDropdown__row__s_M2w";
export var save = "ProjectStickyBarUsersDropdown__save__DAFDO";
export var search = "ProjectStickyBarUsersDropdown__search__tnNgK";
export var searchFieldInput = "ProjectStickyBarUsersDropdown__searchFieldInput__orS1y";
export var searchIcon = "ProjectStickyBarUsersDropdown__searchIcon__y_Pe9";
export var selectAll = "ProjectStickyBarUsersDropdown__selectAll__li2iU";
export var selectButton = "ProjectStickyBarUsersDropdown__selectButton__J75Ml";
export var userJobTitle = "ProjectStickyBarUsersDropdown__userJobTitle__M3mLV";
export var userName = "ProjectStickyBarUsersDropdown__userName__kTEbV";
export var usersList = "ProjectStickyBarUsersDropdown__usersList__T38P_";